import { useContext } from "react";
import { ScoreBoardTeamData, ScoreBoardTeamEvent } from "../../../channel/data-type";
import { LocalDBContext } from "../../../channel/LocalDB";
import { PlayersSelect } from "../../general/input/PlayersSelect";
import { Label } from "../../general/Label";

interface GoalTeamViewProps {
  label: string
  data: ScoreBoardTeamData
  setData(data: ScoreBoardTeamData): void
  type: 'home' | 'away'
}

export function GoalTeamView(props: GoalTeamViewProps) {
  const { db, updateGoal, updateScoreBoard } = useContext(LocalDBContext);
  const list_player = db.match[props.type].players;

  function setTeamEvent(event: ScoreBoardTeamEvent, i: number) {
    props.setData({ ...props.data, list_event: [
      ...props.data.list_event.slice(0, i),
      { ...event },
      ...props.data.list_event.slice(i + 1)
    ] })
  }

  function addTeamEvent() {
    props.setData({ ...props.data, list_event: [
      ...props.data.list_event,
      {
        type: 'goal',
        minutes: '' as any,
        name: ''
      }
    ] })
  }
  function deleteTeamEvent(i: number) {
    props.setData({ ...props.data, list_event: [
      ...props.data.list_event.slice(0, i),
      ...props.data.list_event.slice(i + 1)
    ] })
  }

  return (
    <div className={`flex flex-col gap-[12px]`}>
      <div className={``}>
        { props.label }
      </div>
      <div className={`flex gap-[12px]`}>
        <img 
          className={`h-[120px] object-contain`}
          src={props.data.logo} />
        <div className={`font-bold text-[22px]`}>
          { props.data.team_name }
        </div>
      </div>
      <Label 
        label={`Daftar Goal`}
        className={`flex flex-col !gap-[8px]`}>
        {
          props.data.list_event.map((sbte: ScoreBoardTeamEvent, i: number) => (
            <div 
              key={i}
              className={`flex gap-[8px] items-center`}>
              <input
                className={`w-[100px] outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
                value={sbte.minutes}
                placeholder={'Menit ke-'}
                type={'number'}
                onChange={e => setTeamEvent({ ...sbte, minutes: e.target.value as any }, i)} />
              <PlayersSelect
                players={list_player}
                value={list_player.find(p => p.name === sbte.name)!}
                setValue={player => player && setTeamEvent({ ...sbte, name: player.name }, i)} />
              {/* <input
                className={`flex-1 outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
                value={sbte.name}
                placeholder={'Nama Pemain'}
                onChange={e => setTeamEvent({ ...sbte, name: e.target.value }, i)} /> */}
              <button 
                onClick={() => deleteTeamEvent(i)}
                className={`border border-px border-red-400 text-red-400 rounded-[4px] p-[5px_12px] text-[14px]`}>
                Hapus
              </button>
              <button 
                onClick={() => {
                  const player = list_player.find(p => p.name === sbte.name);
                  new BroadcastChannel('default').postMessage({
                    type: 'goal',
                    data: {
                      player_name: player?.name ?? '',
                      player_number: player?.number ?? '' as any,
                      team_logo: props.data.logo
                    }
                  });
                }}
                className={`border border-px border-sky-400 bg-sky-400 text-white rounded-[4px] p-[5px_12px] text-[14px]`}>
                Tampilkan
              </button>
            </div>
          ))
        }
        <button 
          onClick={addTeamEvent}
          className={`border border-px border-sky-400 text-sky-400 rounded-[4px] p-[5px_12px] text-[14px] self-start`}>
          + Tambah
        </button>
      </Label>
    </div>
  );
}
