import { useContext } from "react";
import { LocalDBContext } from "../../channel/LocalDB";
import { InputPlusMinus } from "../general/input/InputPlusMinus";
import { Label } from "../general/Label";

interface SubpageIdleProps {}

export function SubpageIdle(props: SubpageIdleProps) {
  const { db, updateIdle } = useContext(LocalDBContext);

  function post() {
    new BroadcastChannel('default').postMessage({
      type: 'idle',
      data: db.idle
    });
  }

  return (
    <div className={`flex flex-col gap-[12px] p-[24px]`}>
      <Label label={`Teks 1`}>
        <input
          className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
          value={db.idle.message1}
          placeholder={'Teks 1'}
          onChange={e => updateIdle({ ...db.idle, message1: e.target.value })} />
      </Label>
      <Label label={`Teks 2`}>
        <input
          className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
          value={db.idle.message2}
          placeholder={'Teks 2'}
          onChange={e => updateIdle({ ...db.idle, message2: e.target.value })} />
      </Label>
      <Label label={`Teks 3`}>
        <input
          className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
          value={db.idle.message3 ?? ''}
          placeholder={'Teks 3'}
          onChange={e => updateIdle({ ...db.idle, message3: e.target.value })} />
      </Label>
      <div className={`flex flex-wrap gap-[24px]`}>
        <Label label={`Ukuran bendera`}>
          <InputPlusMinus
            placeholder={`Berdera`}
            value={db.idle?.flag_size ?? 315}
            type="number"
            onChange={e => updateIdle({ ...db.idle, flag_size: e.target.value as any })} />
        </Label>
        <Label label={`Jarak bendera dan teks`}>
          <InputPlusMinus
            placeholder={`Jarak`}
            value={db.idle?.flag_text_space ?? 10}
            type="number"
            onChange={e => updateIdle({ ...db.idle, flag_text_space: e.target.value as any })} />
        </Label>
        <Label label={`Ukuran Teks 1`}>
          <InputPlusMinus
            placeholder={`Ukuran`}
            value={db.idle?.text1_size ?? 65}
            type="number"
            onChange={e => updateIdle({ ...db.idle, text1_size: e.target.value as any })} />
        </Label>
        <Label label={`Ukuran Teks 2`}>
          <InputPlusMinus
            placeholder={`Ukuran`}
            value={db.idle?.text2_size ?? 85}
            type="number"
            onChange={e => updateIdle({ ...db.idle, text2_size: e.target.value as any })} />
        </Label>
        <Label label={`Ukuran Teks 3`}>
          <InputPlusMinus
            placeholder={`Ukuran`}
            value={db.idle?.text3_size ?? 85}
            type="number"
            onChange={e => updateIdle({ ...db.idle, text3_size: e.target.value as any })} />
        </Label>
        <Label label={`Ukuran Teks Waktu`}>
          <InputPlusMinus
            placeholder={`Ukuran`}
            value={db.idle?.datetime_size ?? 35}
            type="number"
            onChange={e => updateIdle({ ...db.idle, datetime_size: e.target.value as any })} />
        </Label>
        <Label label={`Format Waktu`}>
          <input
            className={`bg-white border border-sky-500 border-px p-[4px_12px] rounded-[5px] outline-none w-[240px]`}
            placeholder={`Format`}
            value={db.idle?.datetime_format ?? 'YYYY-MM-DD HH:mm:ss'}
            onChange={e => updateIdle({ ...db.idle, datetime_format: e.target.value as any })} />
        </Label>
        <Label label={`Rata Teks 1`}>
          <select
            className={`bg-white border border-sky-500 border-px p-[4px_12px] rounded-[5px] outline-none`}
            value={db.idle?.text1_align || 'left'}
            onChange={e => updateIdle({ ...db.idle, text1_align: e.target.value as any })}>
            <option value={'left'}>Kiri</option>
            <option value={'center'}>Tengah</option>
            <option value={'right'}>Kanan</option>
          </select>
        </Label>
        <Label label={`Rata Teks 2`}>
          <select
            className={`bg-white border border-sky-500 border-px p-[4px_12px] rounded-[5px] outline-none`}
            value={db.idle?.text2_align || 'left'}
            onChange={e => updateIdle({ ...db.idle, text2_align: e.target.value as any })}>
            <option value={'left'}>Kiri</option>
            <option value={'center'}>Tengah</option>
            <option value={'right'}>Kanan</option>
          </select>
        </Label>
        <Label label={`Rata Teks 3`}>
          <select
            className={`bg-white border border-sky-500 border-px p-[4px_12px] rounded-[5px] outline-none`}
            value={db.idle?.text3_align || 'left'}
            onChange={e => updateIdle({ ...db.idle, text3_align: e.target.value as any })}>
            <option value={'left'}>Kiri</option>
            <option value={'center'}>Tengah</option>
            <option value={'right'}>Kanan</option>
          </select>
        </Label>
        <Label label={`Rata Teks Waktu`}>
          <select
            className={`bg-white border border-sky-500 border-px p-[4px_12px] rounded-[5px] outline-none`}
            value={db.idle?.datetime_align || 'left'}
            onChange={e => updateIdle({ ...db.idle, datetime_align: e.target.value as any })}>
            <option value={'left'}>Kiri</option>
            <option value={'center'}>Tengah</option>
            <option value={'right'}>Kanan</option>
          </select>
        </Label>
      </div>
      <button 
        className={`self-start bg-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={post}>
        Kirim
      </button>
    </div>
  );
}
