import { InputHTMLAttributes } from "react";

interface InputPlusMinusProps extends InputHTMLAttributes<HTMLInputElement> {}

export function InputPlusMinus(props: InputPlusMinusProps) {
  return (
    <div className={`flex items-center gap-[8px]`}>
      <button 
        onClick={() => {
          const numval = Number(props.value);
          if (!isNaN(numval) && numval > 0) {
            props.onChange && props.onChange({ target: { value: numval - 1 }} as any);
          }
        }}
        className={`bg-sky-500 text-white w-[30px] h-[30px] rounded-[5px] cursor-pointer flex items-center justify-center`}>-</button>
      <input 
        {...props}
        className={`border border-px border-sky-500 rounded-[8px] p-[4px_12px] outline-none text-center w-[100px] ${props.className || ''}`} />
      <button 
        onClick={() => {
          const numval = Number(props.value);
          if (!isNaN(numval) && numval > 0) {
            props.onChange && props.onChange({ target: { value: numval + 1 }} as any);
          }
        }}
        className={`bg-sky-500 text-white w-[30px] h-[30px] rounded-[5px] cursor-pointer flex items-center justify-center`}>+</button>
    </div>
  );
}
