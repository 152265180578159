import moment from "moment"
import { DataChannelAllGoal } from "../../channel/data-type"
import { useCountdown } from "../../hooks/useCountdown"
import { WavingFlag } from "../general/WavingFlag"

interface Props {
  data: DataChannelAllGoal
}

export default function AllGoal(props: Props) {
  const startSecond = moment().diff(moment(props.data.data.time_start), 'seconds');

  const { counter } = useCountdown({
    interval: 1000,
    start: props.data.data.time_data.play ? startSecond : (+props.data.data.time_data.minutes * 60 + +props.data.data.time_data.seconds),
    pause: !(props.data.data.time_data?.play ?? false)
  });

  return (
    <div className="z-[9] flex flex-col w-full h-full overflow-hidden self-start pt-[44px] gap-[48px]">

      {/* Flag & Score */}
      <div className="flex flex-row w-full justify-between items-center">
        <WavingFlag image={props.data.data.home.logo} width={170} height={170} />
        <p className="self-center text-gray-50 font-bold text-[80px] text-center leading-none flex-1">
          {String(Math.floor(counter / 60)).padStart(2, '0') + ":" + String(counter % 60).padStart(2, '0')}
        </p>
        <WavingFlag image={props.data.data.away.logo} width={170} height={170} />
      </div>

      {/* List Event */}
      <div className="w-full flex flex-1">

        {/* List Event Home */}
        <div className="flex-1">
          { props.data.data.home.list_event.map((item, index) => {
            return (
              item.type === 'red-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-start mb-5">
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E20C0B] -rotate-12 ml-4"/>
                  <p className="text-[35px] text-[#E20C0B]">{item.minutes + `" ` + item.name}</p>
                </div>
              : item.type === 'yellow-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-start mb-5">
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E7BB19] -rotate-12 ml-4"/>
                  <p className="text-[35px] text-[#E7BB19]">{item.minutes + `" ` + item.name}</p>
                </div>
              :
                <div key={index} className="flex flex-row gap-6 items-center justify-start mb-5">
                  <img className={`w-[50px] h-[50px] object-contain`} src="/images/ball.svg" alt="" />
                  <p className="text-[35px] font-bold text-white">{item.minutes + `" ` + item.name}</p>
                </div> 
            )
          })}
        </div>


        {/* List Event Away */}
        <div className="flex-1">
          { props.data.data.away.list_event.map((item, index) => {
            return (
              item.type === 'red-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-end mb-5">
                  <p className="text-[35px] text-[#E20C0B]">{item.minutes + `" ` + item.name}</p>
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E20C0B] -rotate-12 mr-4"/>
                </div>
              : item.type === 'yellow-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-end mb-5">
                  <p className="text-[35px] text-[#E7BB19]">{item.minutes + `" ` + item.name}</p>
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E7BB19] -rotate-12 mr-4"/>
                </div>
              :
                <div key={index} className="flex flex-row gap-6 items-center justify-end mb-5">
                  <p className="text-[35px] font-bold text-white">{item.minutes + `" ` + item.name}</p>
                  <img className={`w-[50px] h-[50px] object-contain`} src="/images/ball.svg" alt="" />
                </div>
            )
          })}
        </div>
      </div>

    </div>
  )
}
