// import { uploadFile } from "@/utility";
import { InputHTMLAttributes, useEffect, useRef, useState } from "react";

export function InputImage(props: InputHTMLAttributes<HTMLInputElement> & { width?: number, height?: number }) {
  const ref = useRef(null);

  return (
    <div 
      style={{ maxWidth: props.width ?? 150 }}
      className={`border border-px border-gray-100 relative overflow-hidden flex flex-row items-center flex-start`}>
      <div 
        onClick={() => (ref.current as any).click()}
        style={{
          width: props.width ?? 150,
          height: props.height ?? 150,
          maxWidth: props.width ?? 150,
          maxHeight: props.height ?? 150,
        }}
        className={`relative flex items-center justify-center cursor-pointer`}>
        <img 
          src={props.value ? props.value as string : undefined}
          style={{
            width: props.width ?? 150,
            height: props.height ?? 150,
          }}
          className={`object-contain`} />
      </div>
      <input 
        ref={ref}
        onChange={e => {
          const files = e.target.files;
          if (!files) {
            console.log('no files');
            return;
          }
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.onload = (e: ProgressEvent<FileReader>) => {
            props.onChange && props.onChange({
              target: {
                value: e.target?.result ?? ''
              }
            } as any);
          }
          e.target.value = '';
        }}
        className={`bg-transparent outline-none font-inter text-sm p-3 px-5 text-white min-h-[80px]`}
        placeholder={'Placeholder'}
        type={'file'}
        hidden />
    </div>
  );
}
