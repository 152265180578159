import { useContext, useState } from "react";
import { DataChannelScoreBoard, IDataChannel } from "../../channel/data-type";
import { SBTimeView } from "./score-board/SBTimeView";
import { SBTeamView } from "./score-board/SBTeamView";
import { LocalDBContext } from "../../channel/LocalDB";
import { SBTimeView2 } from "./score-board/SBTimeView2";
import moment from "moment";

interface SubpageScoreBoardProps {}

export function SubpageScoreBoard(props: SubpageScoreBoardProps) {
  const { db, updateScoreBoard } = useContext(LocalDBContext);

  function post() {
    new BroadcastChannel('default').postMessage({
      type: 'score-board',
      data: db.score_board
    });
  }

  function onStartStopSpecific(start_seconds: number, stop_seconds: number) {
    const st_start_minutes = Math.floor(start_seconds / 60);
    const st_start_seconds = start_seconds % 60;
    const time_start = db.score_board.time_data.play 
      ? db.score_board.time_start 
      : moment().subtract(+st_start_minutes * 60 + +st_start_seconds, 'seconds').toDate();
    const time_stop = moment(time_start).add(stop_seconds, 'seconds').toDate();
    const new_sb: IDataChannel.ScoreBoard = {
      ...db.score_board,
      time_data: {
        minutes: st_start_minutes,
        seconds: st_start_seconds,
        play: true
      },
      time_start,
      time_stop
    };
    updateScoreBoard(new_sb);
    new BroadcastChannel('default').postMessage({
      type: 'score-board',
      data: new_sb
    });
  }

  return (
    <div className={`flex flex-col gap-[12px] p-[24px]`}>
      <SBTimeView2 
        sb={db.score_board}
        timeStart={db.score_board.time_start}
        data={db.score_board.time_data}
        setData={(time_data: IDataChannel.JamPertandingan, submit) => {
          const new_sb: IDataChannel.ScoreBoard = {
            ...db.score_board,
            time_data,
            time_start: db.score_board.time_data.play 
              ? db.score_board.time_start 
              : moment().subtract(+time_data.minutes * 60 + +time_data.seconds + 1, 'seconds').toDate(),
            time_stop: undefined
          };
          updateScoreBoard(new_sb);
          if (submit) {
            new BroadcastChannel('default').postMessage({
              type: 'score-board',
              data: new_sb
            });
          }
        }}
        onStartStopSpecific={onStartStopSpecific} />
      <div className={`flex gap-[24px]`}>
        <div className={`flex-1 flex flex-col gap-[12px]`}>
          <SBTeamView 
            label={'Home'}
            data={db.score_board.home}
            setData={home => updateScoreBoard({ ...db.score_board, home })} />
        </div>
        <div className={`flex-1 flex flex-col gap-[12px]`}>
          <SBTeamView
            label={'Away'}
            data={db.score_board.away}
            setData={away => updateScoreBoard({ ...db.score_board, away })} />
        </div>
      </div>
      <button 
        className={`self-start bg-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={post}>
        Kirim
      </button>
    </div>
  );
}
