import moment from "moment";
import { DataChannelIdle } from "../../channel/data-type"
import { WavingFlag } from "../general/WavingFlag"
import { useCountdown } from "../../hooks/useCountdown";

interface Props {
  data: DataChannelIdle
}

export default function Home(props: Props) {
  return (
    <div 
      style={{
        gap: `${props.data.data.flag_text_space || 10}px`
      }}
      className="z-[9] flex flex-row items-center">
      <div className={`ml-[70px]`}>
        <WavingFlag 
          speed={'slow'} 
          image={'/images/malut.png'} 
          width={+(props.data.data.flag_size || 315) * 0.57} 
          height={+(props.data.data.flag_size || 315)} />
      </div>
      <div className="flex flex-col flex-1">
        <p 
          style={{
            fontSize: `${props.data.data.text1_size || 65}px`,
            textAlign: props.data.data.text1_align || 'left'
          }}
          className="font-extralight text-white text-left">{props.data.data.message1}</p>
        <p 
          style={{
            fontSize: `${props.data.data.text2_size || 85}px`,
            textAlign: props.data.data.text2_align || 'left'
          }}
          className="font-bold text-white text-left">{props.data.data.message2}</p>
        <p 
          style={{
            fontSize: `${props.data.data.text3_size || 85}px`,
            textAlign: props.data.data.text3_align || 'left'
          }}
          className="font-bold text-white text-left">{props.data.data.message3}</p>
        { props.data.data.datetime_format && <Timer 
          size={props.data.data.datetime_size}
          align={props.data.data.datetime_align}
          format={props.data.data.datetime_format} /> }
      </div>
    </div>
  )
}

function Timer(props: { size?: any, align?: any, format?: any }) {
  const { counter } = useCountdown({
    interval: 1000,
    start: 0
  });

  return <p 
    style={{
      fontSize: `${props.size || 35}px`,
      textAlign: props.align || 'left'
    }}
    className="font-medium text-white text-left mt-[36px]"
    aria-label={String(counter)}>{moment(new Date()).format(props.format || 'YYYY-MM-DD HH:mm:ss')}</p>
}
