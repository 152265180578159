import { useContext } from "react";
import { LocalDBContext } from "../../channel/LocalDB";
import { TeamConfiguration } from "./team-config/TeamConfiguration";
import { Label } from "../general/Label";
import { InputImage } from "../general/input/InputImage";

interface SubpageGlobalConfigProps {}

export function SubpageGlobalConfig(props: SubpageGlobalConfigProps) {
  const { db, updateGlobalConfig } = useContext(LocalDBContext);

  function post() {
    new BroadcastChannel('global-config').postMessage(db.global_config);
  }

  return (
    <div className={`flex flex-col gap-[12px] p-[24px]`}>
      <Label label={`Background`}>
        <InputImage
          width={500}
          height={.57 * 500}
          value={db.global_config?.background_image ?? ''}
          onChange={e => updateGlobalConfig({ ...db.global_config, background_image: e.target.value })} />
      </Label>
      <Label label={`Kecerahan Background: ${(db.global_config.background_opacity * 100).toFixed(0)}%`}>
        <input
          className={`w-[100px] outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all w-[200px]`}
          value={db.global_config.background_opacity ?? .5}
          type={'range'}
          min={0}
          max={1}
          step={.01}
          onChange={e => updateGlobalConfig({ ...db.global_config, background_opacity: Number(e.target.value) })} />
      </Label>
      <button 
        className={`self-start bg-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={post}>
        Kirim
      </button>
    </div>
  );
}
