import { ScoreBoardTeamData, ScoreBoardTeamEvent } from "../../../channel/data-type";
import { Label } from "../../general/Label";

interface SBTeamViewProps {
  label: string
  data: ScoreBoardTeamData
  setData(data: ScoreBoardTeamData): void
}

export function SBTeamView(props: SBTeamViewProps) {
  function setTeamEvent(event: ScoreBoardTeamEvent, i: number) {
    props.setData({ ...props.data, list_event: [
      ...props.data.list_event.slice(0, i),
      { ...event },
      ...props.data.list_event.slice(i + 1)
    ] })
  }

  function addTeamEvent() {
    props.setData({ ...props.data, list_event: [
      ...props.data.list_event,
      {
        type: 'goal',
        minutes: '' as any,
        name: ''
      }
    ] })
  }
  function deleteTeamEvent(i: number) {
    props.setData({ ...props.data, list_event: [
      ...props.data.list_event.slice(0, i),
      ...props.data.list_event.slice(i + 1)
    ] })
  }

  return (
    <div className={`flex flex-col gap-[12px]`}>
      <div className={``}>
        { props.label }
      </div>
      <div className={`flex gap-[12px]`}>
        <img 
          className={`h-[120px] object-contain`}
          src={props.data.logo} />
        <div className={`font-bold text-[22px]`}>
          { props.data.team_name }
        </div>
      </div>
      <Label label={`Total Goal`}>
        <input
          className={`outline-none w-[100px] rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_8px]`}
          value={props.data.goal}
          type={'number'}
          placeholder={'Nomor'}
          onChange={e => props.setData({ ...props.data, goal: e.target.value as any })} />
      </Label>
      {/* <Label 
        label={`Event`}
        className={`flex flex-col !gap-[8px]`}>
        {
          props.data.list_event.map((sbte: ScoreBoardTeamEvent, i: number) => (
            <div 
              key={i}
              className={`flex gap-[8px] items-center`}>
              <select
                className={`w-[120px] outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_0px]`}
                value={sbte.type}
                onChange={e => setTeamEvent({ ...sbte, type: e.target.value as 'goal' | 'yellow-card' | 'red-card' }, i)}>
                <option value={'goal'}>Goal</option>
                <option value={'yellow-card'}>Kartu Kuning</option>
                <option value={'red-card'}>Kartu Merah</option>
              </select>
              <input
                className={`w-[100px] outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
                value={sbte.minutes}
                placeholder={'Menit ke-'}
                type={'number'}
                onChange={e => setTeamEvent({ ...sbte, minutes: e.target.value as any }, i)} />
              <input
                className={`flex-1 outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px]`}
                value={sbte.name}
                placeholder={'Nama Pemain'}
                onChange={e => setTeamEvent({ ...sbte, name: e.target.value }, i)} />
              <button 
                onClick={() => deleteTeamEvent(i)}
                className={`border border-px border-red-400 text-red-400 rounded-[4px] p-[5px_12px] text-[14px]`}>
                Hapus
              </button>
            </div>
          ))
        }
        <button 
          onClick={addTeamEvent}
          className={`border border-px border-sky-400 text-sky-400 rounded-[4px] p-[5px_12px] text-[14px] self-start`}>
          + Tambah
        </button>
      </Label> */}
    </div>
  );
}
