import moment from "moment"
import { DataChannelScoreBoard } from "../../channel/data-type"
import { useCountdown } from "../../hooks/useCountdown"
import { WavingFlag } from "../general/WavingFlag"
import { useEffect } from "react"

interface Props {
  data: DataChannelScoreBoard
}

export default function Scoreboard(props: Props) {
  const startSecond = moment().diff(moment(props.data.data.time_start), 'seconds');

  const { counter } = useCountdown({
    interval: 1000,
    start: props.data.data.time_data.play ? startSecond : (+props.data.data.time_data.minutes * 60 + +props.data.data.time_data.seconds),
    pause: !(props.data.data.time_data?.play ?? false)
  });

  useEffect(() => {
    if (counter % (60 * 45) == 0) {
      new BroadcastChannel('stop-score-board').postMessage({});
    }
  }, [counter]);

  return (
    <div className="z-[9] relative flex flex-col w-full items-center justify-around overflow-hidden py-[24px]">
      <div 
        // style={{
        //   textShadow: '2px 1px white'
        // }}
        className={`flex flex-col gap-[24px] leading-[1.2em] text-white items-center font-bold text-[50px] pb-[30px]`}>
        <div>BRI Liga 1</div>
      </div>

      {/* Team Name */}
      <div className="z-[10] flex flex-row w-full items-center">
        <p className="flex-1 text-[55px] text-white font-bold text-left">{props.data.data.home.team_name}</p>
        <img 
          className={`w-[120px] h-[120px] object-contain rounded-full`}
          src={'/images/BRI_Loga_1_alt.png'} />
        <p className="flex-1 text-[55px] text-white font-bold text-right">{props.data.data.away.team_name}</p>
      </div>

      {/* Flag & Score */}
      <div className="z-[10] flex flex-row w-full justify-between items-center my-[-50px]">
        <div className="flex flex-row flex-1 items-center justify-between gap-[20px] my-16">
          <WavingFlag image={props.data.data.home.logo} width={310} height={310} />
          <p className="text-gray-50 font-bold text-[200px] leading-none">{props.data.data.home.goal}</p>
        </div>

        <p className="text-[#CECECE] font-bold text-[200px] leading-none text-center px-[40px]">-</p>

        <div className="flex flex-row flex-1 items-center justify-between gap-[20px] my-16">
          <p className="text-gray-50 font-bold text-[200px] leading-none">{props.data.data.away.goal}</p>
          <WavingFlag image={props.data.data.away.logo} width={270} height={270} />
        </div>
      </div>

      {/* List Event */}
      <div className="z-[10] w-full">

        {/* List Event Home */}
        {/* <div className="flex-1">
          { props.data.data.home.list_event.map((item, index) => {
            return (
              item.type === 'red-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-start mb-5">
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E20C0B] -rotate-12 ml-4"/>
                  <p className="text-[40px] text-[#E20C0B]">{item.minutes + `" ` + item.name}</p>
                </div>
              : item.type === 'yellow-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-start mb-5">
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E7BB19] -rotate-12 ml-4"/>
                  <p className="text-[40px] text-[#E7BB19]">{item.minutes + `" ` + item.name}</p>
                </div>
              :
                <div key={index} className="flex flex-row gap-6 items-center justify-start mb-5">
                  <img src="/images/ball.svg" alt="" />
                  <p className="text-[40px] font-bold text-white">{item.minutes + `" ` + item.name}</p>
                </div> 
            )
          })}
        </div> */}

        {/* Timer */}
        <p className="self-center text-gray-50 font-bold text-[100px] text-center leading-none">
          {String(Math.floor(counter / 60)).padStart(2, '0') + ":" + String(counter % 60).padStart(2, '0')}
        </p>


        {/* List Event Away */}
        {/* <div className="flex-1">
          { props.data.data.away.list_event.map((item, index) => {
            return (
              item.type === 'red-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-end mb-5">
                  <p className="text-[40px] text-[#E20C0B]">{item.minutes + `" ` + item.name}</p>
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E20C0B] -rotate-12 mr-4"/>
                </div>
              : item.type === 'yellow-card' ? 
                <div key={index} className="flex flex-row gap-6 items-center justify-end mb-5">
                  <p className="text-[40px] text-[#E7BB19]">{item.minutes + `" ` + item.name}</p>
                  <div className="h-[62px] w-[53px] rounded-xl bg-[#E7BB19] -rotate-12 mr-4"/>
                </div>
              :
                <div key={index} className="flex flex-row gap-6 items-center justify-end mb-5">
                  <p className="text-[40px] font-bold text-white">{item.minutes + `" ` + item.name}</p>
                  <img src="/images/ball.svg" alt="" />
                </div>
            )
          })}
        </div> */}
      </div>

    </div>
  )
}
