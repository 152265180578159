import { useContext, useEffect, useRef, useState } from "react";
import { TabHeader } from "../TabHeader";
import { io, Socket } from "socket.io-client";
import { SubpageIdle } from "../tabs/SubpageIdle";
import { SubpageGoal } from "../tabs/SubpageGoal";
import { SubpageIklan } from "../tabs/SubpageIklan";
import { SubpageKartu } from "../tabs/SubpageKartu";
import { SubpageSubstitusi } from "../tabs/SubpageSubstitusi";
import { SubpageScoreBoard } from "../tabs/SubpageScoreBoard";
import { LocalDBContext, LocalDBContextProvider } from "../../channel/LocalDB";
import { SubpageTeamConfig } from "../tabs/SubpageTeamConfig";
import { SubpageGlobalConfig } from "../tabs/SubpageGlobalConfig";

interface ControllerPageProps {}

export function ControllerPage(props: ControllerPageProps) {
  const socket = useRef<Socket>();
  const [socket_instance, setSocketIntance] = useState<Socket>();
  const [tab, setTab] = useState<string>('Idle');

  // useEffect(() => {
  //   // socket.current = io('https://socket.kie-raha.graf.run');
  //   // socket.current.on('connect', () => {
  //   //   setSocketIntance(socket.current);
  //   // });
    
  //   // socket.current.on('idle', (data: any) => {
  //   //   console.log('receive idle message', data);
  //   // });
  //   // return () => {
  //   //   socket.current?.disconnect();
  //   // };
  //   console.log(db.score_board.time_data);
  // }, [db.score_board.time_data]);

  return (
    <LocalDBContextProvider>
      <ScoreboardStopListener />
      <div className={`relative`}>
        <div className={`sticky top-0 z-[99]`}>
          <TabHeader
            tabs={[
              'Idle',
              'Konfigurasi Tim',
              'Score Board',
              'Goal',
              'Kartu',
              'Substitusi',
              'Iklan',
              'Konfigurasi Umum'
            ]}
            selectedTab={tab}
            onTabSelected={setTab} />
        </div>
        <div className={`flex flex-col`}>
          { tab === 'Idle' && <SubpageIdle /> }
          { tab === 'Konfigurasi Tim' && <SubpageTeamConfig /> }
          { tab === 'Goal' && <SubpageGoal /> }
          { tab === 'Iklan' && <SubpageIklan /> }
          { tab === 'Kartu' && <SubpageKartu /> }
          { tab === 'Substitusi' && <SubpageSubstitusi /> }
          { tab === 'Score Board' && <SubpageScoreBoard /> }
          { tab === 'Konfigurasi Umum' && <SubpageGlobalConfig /> }
        </div>
      </div>
    </LocalDBContextProvider>
  );
}

function ScoreboardStopListener() {
  const { db, updateScoreBoard } = useContext(LocalDBContext);

  const listener_stop_score_board = (ev: MessageEvent<any>) => {
    console.log(`received message`);
    const new_sb = {
      ...db.score_board,
      time_data: {
        ...db.score_board.time_data,
        seconds: 0,
        minutes: Math.round(db.score_board.time_data.minutes / 45) * 45,
        play: false
      }
    };
    updateScoreBoard(new_sb);
    new BroadcastChannel('default').postMessage({
      type: 'score-board',
      data: new_sb
    });
  };

  useEffect(() => {
    console.log(db);
    const bc = new BroadcastChannel('stop-score-board');
    bc.addEventListener("message", listener_stop_score_board);

    return () => {
      bc.removeEventListener("message", listener_stop_score_board);
    }
  }, [db.score_board.time_data]);

  return <div hidden />
}
