import React, { useEffect, useState } from "react";
import { IDataChannel, ITeamData } from "./data-type";

export interface LocalDB {
  match: ITeamData.Match
  idle: IDataChannel.Idle
  score_board: IDataChannel.ScoreBoard
  goal: IDataChannel.Goal
  card: IDataChannel.Card
  global_config: IDataChannel.GlobalConfig
}

export const LocalDBContext = React.createContext<{
  db: LocalDB;
  updateIdle(idle: IDataChannel.Idle): void
  updateScoreBoard(score_board: IDataChannel.ScoreBoard): void
  updateGoal(goal: IDataChannel.Goal): void
  updateCard(goal: IDataChannel.Card): void
  updateMatch(match: ITeamData.Match): void
  updateGlobalConfig(match: IDataChannel.GlobalConfig): void
}>({} as any);

export function LocalDBContextProvider(props: { children: any }) {
  const [db, setDB] = useState<LocalDB>(localStorage.getItem('db') ? JSON.parse(localStorage.getItem('db') ?? '{}') : {
    match: {
      home: {
        name: 'Malut United',
        logo: '/images/malut.png',
        players: [{
          position: ITeamData.Position.GK.name,
          name: 'Muhammad Ridwan',
          number: 1
        }, {
          number: 3,
          position: ITeamData.Position.DF.name,
          name: 'Bagus Nirwanto'
        }, {
          number: 4,
          position: ITeamData.Position.DF.name,
          name: 'Rio Saputro'
        }, {
          number: 5,
          position: ITeamData.Position.DF.name,
          name: 'Saddam Tenang'
        }, {
          number: 6,
          position: ITeamData.Position.MF.name,
          name: 'Ahmad Baasith'
        }, {
          number: 7,
          position: ITeamData.Position.FW.name,
          name: 'Adriano Castanheira'
        }, {
          number: 8,
          position: ITeamData.Position.MF.name,
          name: 'Ichlasul Qadri'
        }, {
          number: 9,
          position: ITeamData.Position.FW.name,
          name: 'Diego Martínez'
        }, {
          number: 10,
          position: ITeamData.Position.FW.name,
          name: 'Ilham Armaiyn'
        }, {
          number: 11,
          position: ITeamData.Position.FW.name,
          name: 'Rifal Lastori'
        }, {
          number: 12,
          position: ITeamData.Position.FW.name,
          name: 'Yakob Sayuri'
        }, {
          number: 13,
          position: ITeamData.Position.MF.name,
          name: 'Tatsuro Nagamatsu'
        }, {
          number: 14,
          position: ITeamData.Position.MF.name,
          name: 'Riki Togubu'
        }, {
          number: 15,
          position: ITeamData.Position.MF.name,
          name: 'Finky Pasamba'
        }, {
          number: 16,
          position: ITeamData.Position.MF.name,
          name: 'Wbeymar Angulo'
        }, {
          number: 18,
          position: ITeamData.Position.DF.name,
          name: 'Wahyu Prasetyo'
        }, {
          number: 19,
          position: ITeamData.Position.MF.name,
          name: 'Pramoedya Putra'
        }, {
          number: 20,
          position: ITeamData.Position.FW.name,
          name: 'Rafly Selang'
        }, {
          number: 21,
          position: ITeamData.Position.FW.name,
          name: 'Frets Butuan'
        }, {
          number: 22,
          position: ITeamData.Position.FW.name,
          name: 'Hari Nur Yulianto'
        }, {
          number: 23,
          position: ITeamData.Position.DF.name,
          name: 'Yance Sayuri'
        }, {
          number: 24,
          position: ITeamData.Position.FW.name,
          name: 'Aaron Yekti'
        }, {
          number: 25,
          position: ITeamData.Position.DF.name,
          name: 'Irsan Lestaluhu'
        }, {
          number: 27,
          position: ITeamData.Position.DF.name,
          name: 'Safrudin Tahar'
        }, {
          number: 28,
          position: ITeamData.Position.DF.name,
          name: 'Aditya Putra Dewa'
        }, {
          number: 31,
          position: ITeamData.Position.GK.name,
          name: 'Rifky Tofani'
        }, {
          number: 32,
          position: ITeamData.Position.MF.name,
          name: 'Jorge Correa'
        }, {
          number: 33,
          position: ITeamData.Position.MF.name,
          name: 'Darel Valentino'
        }, {
          number: 37,
          position: ITeamData.Position.DF.name,
          name: 'Firman Ramadhan'
        }, {
          number: 41,
          position: ITeamData.Position.DF.name,
          name: 'Cássio Scheid'
        }, {
          number: 46,
          position: ITeamData.Position.DF.name,
          name: 'Fredyan Wahyu'
        }, {
          number: 69,
          position: ITeamData.Position.MF.name,
          name: 'Manahati Lestusen'
        }, {
          number: 71,
          position: ITeamData.Position.GK.name,
          name: 'Muhammad Fahri'
        }, {
          number: 80,
          position: ITeamData.Position.FW.name,
          name: 'Victor Mansaray'
        }, {
          number: 88,
          position: ITeamData.Position.MF.name,
          name: 'Alwi Slamat'
        }, {
          number: 96,
          position: ITeamData.Position.GK.name,
          name: 'Ray Redondo'
        }, {
          number: 99,
          position: ITeamData.Position.FW.name,
          name: 'Yandi Sofyan'
        }]
      },
      away: {
        name: '',
        logo: '',
        players: []
      }
    },
    idle: {
      message1: 'Selamat datang di stadion',
      message2: 'Gelora Kie Raha',
      message3: ''
    },
    score_board: {
      time_start: new Date(),
      time_data: {
        minutes: 0,
        seconds: 0,
        play: false
      },
      home: {
        goal: 0,
        team_name: 'Malut United',
        logo: '/images/malut.png',
        list_event: []
      },
      away: {
        goal: 0,
        logo: '',
        team_name: '',
        list_event: []
      }
    },
    goal: {
      player_name: '',
      player_number: 0,
      team_logo: ''
    },
    card: {
      type: 'yellow',
      player_name: '',
      player_number: 0,
      team_logo: ''
    },
    global_config: {
      background_image: ''
    }
  });

  useEffect(() => {
    localStorage.setItem('db', JSON.stringify(db));
  }, [db]);

  return (
    <LocalDBContext.Provider value={{
      db,
      updateIdle(idle: IDataChannel.Idle) { setDB({ ...db, idle }); },
      updateScoreBoard(score_board: IDataChannel.ScoreBoard) { setDB({ ...db, score_board }); },
      updateGoal(goal: IDataChannel.Goal) { setDB({ ...db, goal }); },
      updateCard(card: IDataChannel.Card) { setDB({ ...db, card }); },
      updateMatch(match: ITeamData.Match) {
        setDB({
          ...db,
          match,
          score_board: {
            ...db.score_board,
            home: {
              ...db.score_board.home,
              team_name: match.home.name,
              logo: match.home.logo
            },
            away: {
              ...db.score_board.away,
              team_name: match.away.name,
              logo: match.away.logo
            }
          }
        });
      },
      updateGlobalConfig(global_config: IDataChannel.GlobalConfig) { setDB({ ...db, global_config }); },
    }}>
      { props.children }
    </LocalDBContext.Provider>
  );
}
