import moment from "moment";
import { IDataChannel } from "../../../channel/data-type";
import { Label } from "../../general/Label";
import { useCountdown } from "../../../hooks/useCountdown";
import { useEffect } from "react";

interface SBTimeView2Props {
  sb: IDataChannel.ScoreBoard
  timeStart: Date
  data: IDataChannel.JamPertandingan
  setData(data: IDataChannel.JamPertandingan, submit?: boolean): void
  onStartStopSpecific(start_seconds: number, stop_seconds: number): void
}

export function SBTimeView2(props: SBTimeView2Props) {
  const startSecond = moment().diff(moment(props.timeStart), 'seconds');

  const { counter } = useCountdown({
    interval: 1000,
    start: props.data.play ? startSecond : (+props.data.minutes * 60 + +props.data.seconds),
    pause: !(props.data?.play ?? false),
    onTick() {
      if (props.data.play ?? false) {
        const actual_ss = moment().diff(moment(props.timeStart), 'seconds');
        props.setData({ ...props.data, minutes: Math.floor(actual_ss / 60), seconds: actual_ss % 60 });
      }
    }
  }, [props.sb]);

  return (
    <div className={`flex flex-col gap-[12px]`}>
      <div className={`text-zinc-300`}>
        { moment(props.timeStart).format('YYYY-MM-DD hh:mm:ss') } { props.data.play ? 'Play' : 'Pause' }
      </div>
      <div className={``}>
        Jam Pertandingan <span className={`font-bold`}>{String(Math.floor(counter / 60)).padStart(2, '0') + ":" + String(counter % 60).padStart(2, '0')}</span>
      </div>
      <div className={`flex gap-[12px]`}>
        <Label label="Menit">
          <input
            disabled={(props.data?.play ?? false)}
            className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px] w-[100px]`}
            value={props.data?.minutes ?? 0}
            placeholder={'Menit'}
            type={'number'}
            onChange={e => props.setData({ ...props.data, minutes: e.target.value as any })} />
        </Label>
        <Label label="Detik">
          <input
            disabled={(props.data?.play ?? false)}
            className={`outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_18px] w-[100px]`}
            value={props.data?.seconds ?? 0}
            placeholder={'Detik'}
            type={'number'}
            onChange={e => props.setData({ ...props.data, seconds: e.target.value as any })} />
        </Label>
        <Label label="Aksi">
          { props.data.play && <button 
            className={`self-start border border-px border-red-500 text-red-500 p-[8px_18px] rounded-[4px]`}
            onClick={() => props.setData({ ...props.data, play: false }, true)}>
            Pause
          </button> }
          { !props.data.play && <button 
            className={`self-start text-sky-500 border border-px border-sky-500 p-[8px_18px] rounded-[4px]`}
            onClick={() => props.setData({ ...props.data, play: true }, true)}>
            Play
          </button> }
        </Label>
      </div>
      { !props.data.play && <button 
        className={`self-start bg-sky-500 border border-px border-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={() => props.onStartStopSpecific(0 * 60 + 1, 45 * 60)}>
        Mulai 00:00 berhenti 45:00
      </button> }
      { !props.data.play && <button 
        className={`self-start bg-sky-500 border border-px border-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={() => props.onStartStopSpecific(45 * 60 + 1, 90 * 60)}>
        Mulai 45:00 berhenti 90:00
      </button> }
    </div>
  );
}
