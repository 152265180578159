import { table } from "console"
import { useEffect, useState } from "react"

export interface CountdownParam {
  start?: number
  interval: number
  onTick?(): void
  pause?: boolean
}

export function useCountdown(param: CountdownParam, deps?: any[]) {
  const [counter, setCounter] = useState<number>(param.start ?? 0);

  function reset() {
    setCounter(param.start ?? 0);
  }

  useEffect(() => {
    setCounter(param.start ?? 0);
  }, [param.start])

  useEffect(() => {
    if (param.pause) {
      return;
    }
    const x = setTimeout(() => {
      setCounter(counter + 1);
      param.onTick && param.onTick();
    }, param.interval);
    
    return () => {
      clearInterval(x);
    };
  }, [counter, param.pause, param.start, ...(deps ?? [])]);

  return { counter, reset };
}
