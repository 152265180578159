import { useState } from "react";
import { ITeamData } from "../../../channel/data-type";

export interface APSValue {
  type: 'home' | 'away',
  player: ITeamData.Player
}

interface PlayersSelectProps {
  players: ITeamData.Player[]
  value: ITeamData.Player
  setValue(data: ITeamData.Player | null): void
}

export function PlayersSelect(props: PlayersSelectProps) {
  function getPlayerKey(player: ITeamData.Player | undefined) {
    return `${player?.number}-${player?.position}-${player?.name}`;
  }

  return (
    <select
      className={`flex-1 outline-none rounded-[4px] border border-px border-sky-200 focus:border-sky-500 transition transition-all p-[8px_0px]`}
      value={getPlayerKey(props.value)}
      onChange={e => {
        const arr = e.target.value.split('-');
        if (arr.length < 3) {
          return;
        }
        const [number, position, name] = arr;
        props.setValue(props.players.find(p => p.number == +number && p.position == position)!);
      }}>
      <option value={''}>Pilih Pemain</option>
      {
        props.players.map((_player: ITeamData.Player) => (
          <option 
            key={`home-${_player.number}-${_player.position}`}
            value={getPlayerKey(_player)}>
            [{ _player.position }] { _player.number } { _player.name }
          </option>
        ))
      }
    </select>
  );
}
