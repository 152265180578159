import { useEffect, useState } from "react";
import Home from "../index-component/Home";
import { DataChannel, IDataChannel } from "../../channel/data-type";
import Scoreboard from "../index-component/Scoreboard";
import Goal from "../index-component/Goal";
import Card from "../index-component/Card";
import AllGoal from "../index-component/AllGoal";

interface IndexPageProps {}

export function IndexPage(props: IndexPageProps) {
  const [data, setData] = useState<DataChannel | null>(null);
  const [global_config, setGlobalConfig] = useState<IDataChannel.GlobalConfig>({
    background_image: '/images/bg.png',
    background_opacity: .5
  });

  const listener = (ev: MessageEvent<DataChannel>) => {
    setData(ev.data)
  };
  const listener_global_config = (ev: MessageEvent<IDataChannel.GlobalConfig>) => {
    setGlobalConfig(ev.data)
  };

  useEffect(() => {
    const bc = new BroadcastChannel('default');
    bc.addEventListener("message", listener);

    return () => {
      bc.removeEventListener("message", listener);
    }
  }, []);

  useEffect(() => {
    const bc = new BroadcastChannel('global-config');
    bc.addEventListener("message", listener_global_config);

    return () => {
      bc.removeEventListener("message", listener_global_config);
    }
  }, []);

  return (
    <div 
      className="w-full h-screen overflow-hidden flex justify-center items-center bg-black font-inter px-[60px]">
      <div 
        style={{
          opacity: `${global_config.background_opacity * 100}%`
        }}
        className={`absolute w-full h-full z-[1] top-0 left-0`}>
        <img 
          className={`w-full h-full`}
          src={global_config.background_image} />
      </div>
      {data?.type === 'idle' && <Home data={data} />}
      {data?.type === 'score-board' && <Scoreboard data={data} />}
      {data?.type === 'all-goal' && <AllGoal data={data} />}
      {data?.type === 'goal' && <Goal data={data} />}
      {data?.type === 'card' && <Card data={data} />}
    </div>
  );
}
