import { useContext, useEffect, useState } from "react";
import { DataChannelGoal, ITeamData } from "../../channel/data-type";
import { Label } from "../general/Label";
import { LocalDBContext } from "../../channel/LocalDB";
import { AllPlayersSelect, APSValue } from "../general/input/AllPlayersSelect";
import { GoalTeamView } from "./goal/GoalTeamView";

interface SubpageGoalProps {}

export function SubpageGoal(props: SubpageGoalProps) {
  const { db, updateScoreBoard } = useContext(LocalDBContext);

  function post() {
    new BroadcastChannel('default').postMessage({
      type: 'all-goal',
      data: db.score_board
    });
  }

  return (
    <div className={`flex flex-col gap-[12px] p-[24px]`}>
      <div className={`flex gap-[24px]`}>
        <div className={`flex-1 flex flex-col gap-[12px]`}>
          <GoalTeamView 
            label={'Home'}
            type={'home'}
            data={db.score_board.home}
            setData={home => updateScoreBoard({ ...db.score_board, home })} />
        </div>
        <div className={`flex-1 flex flex-col gap-[12px]`}>
          <GoalTeamView
            label={'Away'}
            type={'away'}
            data={db.score_board.away}
            setData={away => updateScoreBoard({ ...db.score_board, away })} />
        </div>
      </div>
      <button 
        className={`self-start bg-sky-500 text-white p-[8px_18px] rounded-[4px]`}
        onClick={post}>
        Tampilkan Seluruh Goal
      </button>
    </div>
  );
}
